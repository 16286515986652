import React from "react";
import johnsoncitymeta from '../assets/images/meridenmeta.jpg'
import bgImage from '../assets/images/overlandpark5.jpg'

import {Box, Button, Flex, Heading, Hide, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeBranch, GiTreeDoor, GiTreeRoots} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaStar, FaTree} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople} from "react-icons/md";
import {RiEmotionHappyLine} from "react-icons/ri";
import {AiOutlineClockCircle, AiOutlineScissor} from "react-icons/ai";

const phoneNumber = "(914) 810-9992";
const telLink = "tel:6145551234";
const phoneNumberWithDashes = "914-810-9992";

export const SiteData = {
    hoursOfOperation: "24/7",
    "city": "New Rochelle, NY",
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    "buttonCtaText": () => <Flex>Ready for Expert Tree Care in New Rochelle? Book Your&nbsp;<Text
        textDecoration={'underline'} display={'inline'}>Free Consultation Today!</Text></Flex>,
    "keywords": 'Tree Care, services, professional, affordable, experienced, trimming, New Rochelle, NY.',
    "footerText": 'New Rochelle Tree Service, All rights reserved.',
    "ogImage": johnsoncitymeta,
    "navLinks": [
        {
            "title": 'Home',
            "href": '/'
        },
        {
            "title": 'Services',
            "href": '/services/'
        },
        {
            "title": 'Blog',
            "href": '/blog/'
        },
        {
            "title": 'About',
            "href": '/about/'
        },
        {
            "title": 'Contact Us',
            "href": '/contact/'
        },
        {
            "title": 'Careers',
            "href": '/careers/',
            "hideFromTopNav": true
        },
    ],
    "signupForm": {
        "intro": () => <Text>Welcome to Columbus Tree Service, your trusted partner for exceptional tree care in
            Columbus, Ohio. Our team of seasoned arborists understands the unique flora and landscape challenges of the
            Columbus area, and we are excited to offer our expertise to you. For top-quality tree services, reach out to
            us at <Link href={telLink} textDecoration={'underline'}>{phoneNumber}</Link> today!</Text>,
        "servicesTitle": 'Our Services:',
    },
    "homepage": {
        "bgImage": bgImage,
        "url": 'https://www.newrochelletreeservice.com',
        "metaData": {
            "title": 'New Rochelle Tree Service: Your Local Experts | Call (914) 810-9992',
            "description": 'Expert tree care and maintenance services in New Rochelle, NY. Contact New Rochelle Tree Service today for reliable, professional tree care solutions.'
        },
        "h1": 'Professional Tree Care Services in New Rochelle, NY',
        "subtitle": 'Comprehensive Tree Care - Trimming, Removal, Maintenance, and More',
        "servicesTitle": 'Why Choose New Rochelle Tree Service',
        "values": [
            {
                "id": 0,
                "number": "Over 7500",
                "name": "Trees Serviced",
                "icon": GiTreeBranch,
            },
            {
                "id": 1,
                "number": "Top Rated",
                "name": "Customer Reviews",
                "icon": FaStar,
            },
            {
                "id": 2,
                "number": "100%",
                "name": "Client Satisfaction",
                "icon": RiEmotionHappyLine,
            },
            {
                "id": 3,
                "number": "24/7",
                "name": "Emergency Response",
                "icon": AiOutlineClockCircle,
            },
        ],
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Expert Arborists:</b> Our New Rochelle-based arborists bring expertise in local
                    tree care, ensuring each service is perfectly suited to the area’s unique tree species and climate.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Safe and Efficient Tree Services:</b> We prioritize safety and efficiency,
                    utilizing the latest equipment and techniques for all tree care services in New Rochelle.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Affordable Pricing:</b> Quality tree care should be accessible. We offer
                    competitive pricing without compromising on the quality of our services.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Comprehensive Tree Care:</b> From routine maintenance to emergency services, our
                    comprehensive range covers everything your trees need to thrive.</Text>
            },
            {
                "id": '4',
                "text": () => <Text><b>Local Experience:</b> Being based in New Rochelle, we have a deep understanding
                    of the local environment, which reflects in the quality of our tree care services.</Text>
            }
        ],
        "content": [
            <>
                <Text>
                    <Heading as="h2" size={'md'} pb='2'>New Rochelle Tree Service: Your Premier Destination for Expert
                        Tree Care in New Rochelle, NY</Heading>
                    Welcome to New Rochelle Tree Service, your go-to source for exceptional tree care services in New
                    Rochelle, NY. We are a leading tree removal company offering services created to meet every aspect
                    of tree care. Our specialized tree care arborists are committed to delivering top-notch tree care
                    solutions, from precision tree trimming and effective stump grinding to advanced tree maintenance
                    practices. With a focus on enhancing your trees' health, beauty, and safety, we leverage our
                    extensive expertise and eco-friendly approaches to ensure the well-being of your green spaces.
                    Dedicated to Excellence in Tree Care
                </Text>
                <Heading as="h3" size={'md'} mb={4}>Dedicated to Excellence in Tree Care</Heading>
                <Text fontSize="md">
                    At New Rochelle Tree Service, we are more than a tree removal company; we are caretakers of the
                    environment. With a team of certified arborists and seasoned experts, we deliver comprehensive,
                    reliable tree care services tailored to the unique needs of New Rochelle's diverse landscapes.
                    From extensive tree management to meticulous tree stump removal, our mission is to enhance your
                    property's beauty and safety through sustainable practices.
                </Text>
                <Link href="/about/" my={2}>Learn More About Us</Link>
                <Text>
                    <Heading as="h3" size={'md'} pb='2'>Comprehensive and Reliable Tree Care Services in New
                        Rochelle</Heading>
                    We proudly provide an array of expert tree care services meeting the diverse needs of our clients:
                    <UnorderedList>
                        <ListItem><Link fontWeight={'bold'} href={"/tree-trimming"}>Tree Trimming:</Link> Employ our
                            professional trimming
                            and pruning services to maintain the health and aesthetics of your trees. Our skilled team
                            knows precisely how to prune trees to promote their growth and vitality.</ListItem>
                        <ListItem><Link fontWeight={'bold'} href={"/stump-grinding"}>Stump Grinding:</Link> Opt for our
                            efficient stump
                            removal solutions to enhance the appearance and safety of your landscape. Tree stump removal
                            is executed with precision and care, ensuring minimal impact on your property.</ListItem>
                        <ListItem><Link fontWeight={'bold'} href={"/tree-health-consulting"}>Tree
                            Maintenance:</Link> Benefit from our
                            comprehensive tree health services, designed for the long-term care and preservation of your
                            trees.</ListItem>
                        <ListItem><Link fontWeight={'bold'} href={"/tree-planting"}>Tree Planting:</Link> Trust our
                            expertise in tree
                            planting and transplanting to encourage robust tree growth and enrich your outdoor
                            environment.</ListItem>
                        <ListItem><Link fontWeight={'bold'} href={"/tree-health-consulting"}>Tree Health
                            Consulting:</Link> Gain valuable
                            insights from our certified arborists to make informed decisions about your tree care
                            practices.</ListItem>
                        <ListItem><a style={{fontWeight: 'bold'}} href={"https://www.1treeemergency.com/"}
                                     target={"_blank"} rel={"noreferrer"}>Emergency Tree
                            Services</a>: Access rapid
                            response and reliable tree care services when urgent needs arise, ensuring the safety and
                            integrity of your property.</ListItem>
                        <ListItem><Link fontWeight={'bold'} href={"/arborist-consultations"}>Arborist
                            Consultations:</Link> Receive expert
                            advice on tree health, risk assessment, and management strategies from our knowledgeable
                            arborists.</ListItem>
                        <ListItem><Link fontWeight={'bold'} href={"/land-clearing"}>Land Clearing:</Link> Take advantage
                            of our efficient
                            land clearing services for tree removal and property preparation, facilitating development
                            and landscape management projects.</ListItem>
                    </UnorderedList>
                </Text>
                <Box pb={2}>
                    <Heading as="h2" size="md" mb={4}>Why Trust Us with Your Trees?</Heading>
                    <UnorderedList spacing={3}>
                        <ListItem><strong>Certified Arborists:</strong> Our expertise ensures the best care for your
                            trees.</ListItem>
                        <ListItem><strong>Eco-Friendly Practices:</strong> Sustainable methods to protect the
                            environment.</ListItem>
                        <ListItem><strong>24/7 Emergency Services:</strong> Ready to respond when you need us the most.</ListItem>
                        <ListItem><strong>Competitive Pricing:</strong> Quality services at affordable rates, with free
                            estimates.</ListItem>
                    </UnorderedList>
                </Box>
                <Text>
                    <Heading as="h3" size={'md'} pb='2'>Dedication to Quality, Safety, and Eco-Friendly
                        Practices</Heading>
                    New Rochelle Tree Service is deeply committed to delivering high-quality, reliable tree care
                    services while embracing environmentally sustainable practices. Our dedication to the health and
                    longevity of your trees is matched by our commitment to improving the beauty and safety of your
                    property. We utilize the latest techniques and eco-friendly practices to ensure our services exceed
                    expectations. Why New Rochelle Tree Service Stands Out
                </Text>
                <Text>
                    <Heading as="h3" size={'md'} pb='2'>Why New Rochelle Tree Service Stands Out</Heading>
                    Choosing New Rochelle Tree Service means opting for a tree care service that excels in expertise,
                    eco-friendliness, and professional service delivery. We provide tailored solutions backed by
                    competitive pricing. Our team is passionate about beautifying New Rochelle through exceptional tree
                    care, ensuring complete customer satisfaction with every project we undertake. Comprehensive
                    Solutions for All Your Tree Care Requirements
                </Text>
                <Text>
                    <Heading as="h3" size={'md'} pb='2'>Comprehensive Solutions for All Your Tree Care
                        Requirements</Heading>
                    Our experienced team of experts are prepared to provide professional and reliable tree care services
                    to address a full spectrum of needs. Whether you require routine maintenance, primary lawn care,
                    tree removal, stump grinding, or emergency <a href={"https://www.puyalluptreeservice.net/"}
                                                                  rel={"noreferrer"} target={"_blank"}>tree service</a>,
                    we ensure your trees and property's health
                    and safety. Trust us to be your all-in-one solution for top-notch tree care. Connect with New
                    Rochelle Tree Service Today
                </Text>
                <Text>
                    <Heading as="h3" size={'md'} pb='2'>Connect with New Rochelle Tree Service Today</Heading>
                    Experience the difference with New Rochelle Tree Service. For unparalleled tree care services in New
                    Rochelle, NY, contact us at (914) 810-9992. Get a personalized quote and discover why we are the
                    preferred <a href={"https://www.oregoncitytreecare.com/"} target={"_blank"} rel={"noreferrer"}>tree
                    service</a> provider in the region. Our commitment to excellence and dedication to your
                    satisfaction make us the ideal partner for all your tree care needs. Ready to Enhance Your
                    Landscape?
                </Text>
                <Heading as="h2" size="lg" mt={4}>Ready to Enhance Your Landscape?</Heading>
                <Text fontSize="md" mb={4}>Contact New Rochelle Tree Service today for a free estimate on your tree care
                    needs.</Text>
                <Button as={'a'} href={telLink} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/> Call for <Hide
                    below='md'>Immediate</Hide> Assistance</Button>
            </>
        ],
        "footerHeading": 'Contact New Rochelle Tree Service',
        "footerText": () => <>For professional tree services in New Rochelle and the surrounding areas, reach out to us
            today. Call or fill out our <Link href={'/contact'}>online contact form</Link> for non-emergency inquiries.
            Our team is here for you and all of your tree care needs.</>,
        "footerText2": 'Ensuring the Health and Beauty of Your Trees is Our Priority',
        "servicesHeader": 'Our Extensive Range of Tree Services for New Rochelle’s Unique Needs',
        "servicesSubtitle": () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Explore our <Link
            href={'/services'}>suite of services</Link> – from emergency tree removal to detailed tree health
            maintenance, New Rochelle Tree Service is your one-stop solution for all tree-related needs.</Text>,
        "featuredServices": [
            {
                "name": 'Tree Trimming',
                "description": 'New Rochelle Tree Service provides expert tree trimming services, ensuring your trees are healthy, safe, and aesthetically pleasing. Our team is skilled in the latest trimming techniques tailored to the unique needs of each tree species in the New Rochelle area.',
                "icon": BiCut,
                "path": '/tree-trimming/',
                "linkTitle": 'Tree Trimming'
            },
            {
                "name": 'Stump Grinding',
                "description": 'Our stump grinding service is designed to efficiently and safely remove unsightly tree stumps from your property, enhancing its appearance and usability. We employ advanced equipment to ensure a thorough and eco-friendly stump removal process.',
                "icon": GiTreeRoots,
                "path": '/stump-grinding/',
                "linkTitle": 'Stump Grinding'
            },
            {
                "name": 'Tree Health Consulting',
                "description": 'Our certified arborists offer comprehensive tree health consulting services to assess, diagnose, and manage the health of your trees. We provide personalized care plans and advice to ensure the longevity and vitality of your green assets.',
                "icon": FaConciergeBell,
                "path": '/tree-health-consulting/',
                "linkTitle": 'Tree Health Consulting'
            },
            {
                "name": 'Emergency Tree Services',
                "description": 'New Rochelle Tree Service is equipped to handle emergency tree situations, including storm damage and hazardous tree conditions. Our team responds promptly and effectively to ensure the safety of your property and its residents.',
                "icon": MdOutlineEmergency,
                "path": '/emergency-tree-services/',
                "linkTitle": 'Emergency Tree Services'
            },
            {
                "name": 'Arborist Consultations',
                "description": 'Benefit from our expert arborist consultations to gain insights into tree care, risk assessment, and management strategies. We provide valuable advice and solutions for maintaining healthy, sustainable landscapes in New Rochelle.',
                "icon": MdOutlineNaturePeople,
                "path": '/arborist-consultations/',
                "linkTitle": 'Arborist Consultations'
            },
            {
                "name": 'Land Clearing',
                "description": 'Our land clearing services are essential for construction, landscaping, and development projects. We ensure an efficient and environmentally responsible process, clearing your land while preserving its ecological balance.',
                "icon": FaRegHandScissors,
                "path": '/land-clearing/',
                "linkTitle": 'Land Clearing'
            },
            {
                "name": 'Tree Planting and Transplanting',
                "description": 'We offer expert tree planting and transplanting services, ensuring the right tree is planted in the right place. Our team provides careful handling and placement of trees to promote healthy growth and integration into your landscape.',
                "icon": GiTreeDoor,
                "path": '/tree-planting/',
                "linkTitle": 'Tree Planting and Transplanting'
            },
            {
                "name": 'Tree Maintenance',
                "description": 'Our tree maintenance services cover a wide range of care, from regular pruning to pest control and fertilization. We focus on keeping your trees in optimal condition, enhancing the beauty and health of your landscape.',
                "icon": FaTree,
                "path": '/tree-health-consulting/',
                "linkTitle": 'Tree Maintenance'
            }
        ]
    },
    "careers": {
        "url": "https://www.newrochelletreeservice.com/careers/",
        "metaData": {
            "title": "Career Opportunities at New Rochelle Tree Service, New Rochelle, NY",
            "description": "Join the team at New Rochelle Tree Service and build a career in arboriculture. We offer exciting opportunities for tree care professionals in New Rochelle, NY."
        },
        "h1": "Join Our Team at New Rochelle Tree Service",
        "h2": [
            "Career Opportunities in Tree Care",
            "Our Commitment to Employee Development",
            "Join a Leading Tree Service Team in New Rochelle"
        ],
        "headingText": {
            "whyJoin": "Be part of a team where your passion for the environment and tree care is valued. Join us in providing top-notch tree services in New Rochelle, NY.",
            "openings": "We're looking for skilled arborists, tree climbers, and support staff who are passionate about tree care and customer service.",
            "commitment": "At New Rochelle Tree Service, we invest in our team's growth, offering training, competitive compensation, and a dynamic work environment."
        },
        "content": [
            <Text>Explore career opportunities at New Rochelle Tree Service, where you can grow professionally in the
                field of arboriculture. We are a family of dedicated professionals, committed to excellence in tree
                services.</Text>,
            <Text>Our strength lies in our team. We seek individuals dedicated to quality tree care, whether experienced
                professionals or those new to the field. We offer a supportive environment where your skills and
                contributions are valued.</Text>,
            <Text>Our employees enjoy a culture of growth and learning. We provide comprehensive training, competitive
                pay, and state-of-the-art equipment to ensure a safe and rewarding workplace.</Text>,
            <Text>We take pride in serving the New Rochelle community and uphold a commitment to excellence and
                professionalism. Join us in making a difference in urban forestry and landscape management.</Text>,
            <Text>Interested in a tree care career in New Rochelle, NY? Check our current job openings and find your
                place in our growing team. We offer roles ranging from skilled tree technicians to customer service
                representatives.</Text>,
            <Text>Apply today and be part of a team that's shaping the future of tree care in New Rochelle. Visit <a
                href='https://www.newrochelletreeservice.com/careers/'>our career page</a> to learn more and submit your
                application.</Text>,
        ]
    },
    "about": {
        "url": "https://www.newrochelletreeservice.com/about/",
        "metaData": {
            "title": "About New Rochelle Tree Service | Expert Tree Care in New Rochelle, NY",
            "description": "Learn about New Rochelle Tree Service, offering professional tree care services in New Rochelle, NY. Discover our commitment to quality, safety, and the environment.",
        },
        "h1": "About New Rochelle Tree Service",
        "h2": [
            "Commitment to Quality Tree Care",
            "Our Team of Experienced Arborists",
            "Wide Range of Tree Services in New Rochelle"
        ],
        "headingText": {
            "commitment": "Dedicated to delivering superior tree care, we focus on enhancing the health and beauty of New Rochelle's trees and environment.",
            "team": "Our skilled arborists and tree care professionals are committed to excellence, offering top-tier services tailored to the unique needs of New Rochelle's landscapes.",
            "services": "From tree pruning and removal to health assessments and emergency services, we provide a full spectrum of tree care solutions to keep your trees in optimal condition."
        },
        "services": [
            {
                "id": "0",
                "title": "Tree Pruning & Trimming",
                "href": "/tree-pruning",
                "icon": AiOutlineScissor,
                "description": (
                    <Text>
                        Precision pruning and trimming to maintain the health and appearance of your trees, performed by
                        our skilled arborists.
                    </Text>
                ),
            },
            {
                "id": "1",
                "title": "Tree Removal",
                "href": "/tree-removal",
                "icon": GiChoppedSkull,
                "description": (
                    <Text>
                        Safe and efficient tree removal services for hazardous or unwanted trees, ensuring your
                        property’s safety and aesthetic appeal.
                    </Text>
                ),
            },
            {
                "id": "2",
                "title": "Stump Grinding & Removal",
                "href": "/stump-grinding",
                "icon": GiStumpRegrowth,
                "description": (
                    <Text>
                        Comprehensive stump grinding and removal services to clear your landscape and prepare it for new
                        growth or landscaping projects.
                    </Text>
                ),
            },
            {
                "id": "3",
                "title": "Emergency Tree Services",
                "href": "/emergency-tree-services",
                "icon": MdEmergencyShare,
                "description": (
                    <Text>
                        Prompt and reliable emergency tree services for storm damage, fallen trees, or urgent tree care
                        needs.
                    </Text>
                ),
            },
            {
                "id": "4",
                "title": "Tree Health Consulting",
                "href": "/tree-health-consulting",
                "icon": MdHealthAndSafety,
                "description": (
                    <Text>
                        Expert assessments to diagnose and treat tree diseases and pests, ensuring the longevity and
                        vitality of your trees.
                    </Text>
                ),
            },
            {
                "id": "5",
                "title": "Arborist Consultations",
                "href": "/arborist-consultations",
                "icon": BiConversation,
                "description": (
                    <Text>
                        Professional arborist consultations to advise on tree care, planting, and maintenance for
                        healthy and sustainable landscapes.
                    </Text>
                ),
            },
            {
                "id": "6",
                "title": "Land Clearing & Lot Preparation",
                "href": "/land-clearing",
                "icon": GiLandMine,
                "description": (
                    <Text>
                        Efficient land clearing services for construction projects, landscaping, and property
                        development, with a focus on environmental responsibility.
                    </Text>
                ),
            },
        ]
    },
    "testimonials": {
        "h1": 'Testimonials',
        "heading": 'Hear What Our Customers Say – New Rochelle Tree Service',
        "testimonials": [
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
                "name": 'Linda G.',
                "title": 'New Rochelle, NY',
                "quote": '"I was impressed by the professionalism of New Rochelle Tree Service. Their team expertly handled a difficult tree removal in my yard. The care and efficiency they showed were exceptional."'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a0425d',
                "name": 'Carlos R.',
                "title": 'New Rochelle, NY',
                "quote": '"The team at New Rochelle Tree Service did a fantastic job with tree pruning. They were knowledgeable, courteous, and left my property looking neat and tidy."'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a0804d',
                "name": 'Emily F.',
                "title": 'New Rochelle, NY',
                "quote": '"I called New Rochelle Tree Service for an emergency tree removal after a storm, and they were quick to respond and handle the situation with utmost care and safety. Truly reliable service."'
            },
        ]
    },
    "services": {
        "metaData": {
            "title": 'Professional Tree Care Services in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Explore our extensive range of tree care services in New Rochelle, NY. From tree pruning to comprehensive tree maintenance, our team provides expert solutions.'
        },
        "h1": 'Expert Tree Services in New Rochelle, NY',
        "h2": 'Comprehensive Tree Care Solutions',
        "subtext": 'New Rochelle Tree Service offers a variety of expert tree care services, focusing on enhancing the beauty and health of your landscape.',
        "footerHeading": 'Transform Your Landscape with Our Expertise',
        "footerText2": () => <>Contact us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for a free quote.
            Discover the exceptional service of New Rochelle Tree Service today!</>,
        "footerText": () => <>Your trees' health and beauty are our priority. For top-quality tree care in New Rochelle,
            NY, rely on New Rochelle Tree Service.</>,
        "content": <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">New Rochelle Tree Service: Your Tree Care Partner</Heading>
                <Text>Our commitment at New Rochelle Tree Service is to provide exceptional tree care services. Our
                    skilled team of arborists is equipped to handle every aspect of tree care, from routine maintenance
                    to complex tree health issues.</Text>

                <Heading as="h3" size="lg">Our Range of Tree Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Tree Trimming and Pruning:</b> Our precise trimming and pruning services are
                        tailored to promote the health and aesthetics of your trees, ensuring their safety and
                        beauty.</Text></ListItem>
                    <ListItem><Text><b>Emergency Tree Services:</b> We offer quick and reliable emergency services for
                        storm-damaged trees or other urgent tree care needs, ensuring the safety of your
                        property.</Text></ListItem>
                    <ListItem><Text><b>Stump Grinding and Removal:</b> Our stump grinding services efficiently remove
                        unwanted stumps, clearing the way for new growth or landscaping opportunities.</Text></ListItem>
                    <ListItem><Text><b>Tree Health Assessments:</b> Expert evaluations by our arborists ensure the
                        health and vitality of your trees, with services tailored to the unique New Rochelle
                        environment.</Text></ListItem>
                    <ListItem><Text><b>Arborist Consultations:</b> In-depth consultations provide insights and advice on
                        tree health, risk assessment, and proper care strategies.</Text></ListItem>
                    <ListItem><Text><b>Land Clearing:</b> Our comprehensive land clearing services prepare your property
                        for new developments, ensuring an efficient and environmentally responsible
                        process.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Customized Tree Care Solutions</Heading>
                <Text>At New Rochelle Tree Service, we understand that every tree and landscape is unique. We offer
                    customized solutions to ensure the best possible care for your trees and property.</Text>

                <Heading as="h3" size="lg">Safety and Environmental Responsibility</Heading>
                <Text>We are committed to the highest standards of safety and environmental care, ensuring our services
                    are not only effective but also sustainable.</Text>

                <Heading as="h3" size="lg">Contact New Rochelle Tree Service</Heading>
                <Text>For professional tree care services in New Rochelle, NY, trust the experts at New Rochelle Tree
                    Service. Contact us today for a consultation or to schedule our services.</Text>
            </Stack>
        </Box>,
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Tree Pruning and Trimming:</b> Essential for maintaining tree health and
                    aesthetics, our pruning and trimming services address the specific needs of trees in the New
                    Rochelle area.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Stump Grinding:</b> Removing stumps not only enhances the appearance of your
                    landscape but also prevents potential hazards. Our stump grinding service is thorough and efficient.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Tree Removal:</b> We safely remove trees that pose risks due to disease, damage,
                    or other factors, using techniques that prioritize safety and minimize impact on your
                    property.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Tree Health Consulting:</b> Our arborists assess the health of your trees,
                    providing expert advice and treatment options to ensure their long-term well-being.</Text>
            },
            {
                "id": '4',
                "text": () => <Text><b>Emergency Tree Services:</b> In the event of a storm or other emergencies, our
                    team responds quickly to address any immediate tree-related hazards.</Text>
            },
            {
                "id": '5',
                "text": () => <Text><b>Arborist Consultations:</b> Our professional arborists offer consultations to
                    advise on the best care and maintenance practices for your trees.</Text>
            },
            {
                "id": '6',
                "text": () => <Text><b>Land Clearing:</b> We provide comprehensive land clearing services, from tree and
                    brush removal to stump grinding, preparing your land for its next use.</Text>
            },
        ],
        "whyh2": 'Why New Rochelle Tree Service?',
        "whySubtext": () => <>Our team of experienced professionals is dedicated to providing the highest quality tree
            care services in New Rochelle. Reach out to us at <Link
                href={phoneNumber}>{phoneNumberWithDashes}</Link> for exceptional tree care.</>,
        "whyServices": [
            {
                "id": '0',
                "text": () => <Text><b>Expertise and Experience:</b> Our team's extensive experience and knowledge in
                    tree care ensure the highest quality service for every project.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Commitment to Customers:</b> We prioritize our customers' needs and satisfaction,
                    ensuring that we meet and exceed their expectations with every service.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Local Knowledge:</b> Our deep understanding of New Rochelle's unique environment
                    and tree species allows us to provide specialized and effective tree care services.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Quality and Safety:</b> We adhere to the highest standards of quality and safety
                    in all our services, ensuring the well-being of your trees and property.</Text>
            },
        ]
    },
    "contact": {
        "url": 'https://www.topcolumbustreeservice.com/contact/',
        "metaData": {
            "title": `Contact Columbus Tree Service | Call ${phoneNumber}`,
            "description": 'Connect with Columbus Tree Service for expert tree care services in Columbus, OH. We are dedicated to maintaining the health and beauty of your trees.',
        },
        "h1": 'Columbus Tree Service',
        "subtitle": 'Thank you for choosing Columbus Tree Service. We are a committed tree service company operating in Columbus, Ohio, offering reliable tree care services to our valued customers. Our professional and friendly team is eager to assist with all your tree care requirements.',
        "sectionHeading": 'Why Choose Columbus Tree Service?',
        "signUpDescription": 'To reach out to us online, kindly fill out the form below. We will respond as promptly as possible.',
        "feedbackText": 'Your feedback is valuable to us. If you have any questions or concerns, we would be delighted to hear from you. Ensuring your satisfaction is our highest priority.',
        "closingText": () => <>Providing trusted tree care services in Columbus, OH.</>,
        "valueProps": [
            {
                "title": 'Certified Arborists:',
                "description": 'Our team of certified arborists possesses years of experience and knowledge in providing top-notch tree care. We pride ourselves on delivering exceptional service to ensure the well-being and aesthetics of your trees.',
            },
            {
                "title": 'Comprehensive Tree Services:',
                "description": 'From regular tree maintenance to removal and stump grinding, we offer an extensive range of services. Our skilled team is equipped with the right tools to cater to all your tree care needs.'
            },
            {
                "title": 'Modern Equipment:',
                "description": 'We utilize high-quality, up-to-date equipment to ensure efficient and secure tree care services. Our equipment allows us to handle trees of any size and condition with ease.'
            },
            {
                "title": 'Environmentally Aware:',
                "description": 'Understanding the ecological significance of trees, we are committed to delivering sustainable services. We follow best practices to minimize environmental impact during tree removal or any other services.'
            },
            {
                "title": 'Customer Satisfaction:',
                "description": 'At Columbus Tree Service, exceeding your expectations is our aim. We are dedicated to providing superior customer service and ensuring a smooth experience from start to finish.'
            },
        ]
    },
    "blog": {
        "url": 'https://www.newrochelletreeservice.com/blog/',
        "metaData": {
            "title": 'New Rochelle Tree Service Blog: Tree Care Insights and Tips',
            "description": 'Expert advice and latest trends in tree care from New Rochelle Tree Service. Discover helpful tips and insights on maintaining healthy, beautiful trees in New Rochelle, NY.',
        },
        "h1": 'Latest from New Rochelle Tree Service Blog',
        "h2": 'Informative Articles and Tree Care Tips',
        "heading": 'Our blog offers valuable information and advice on tree care, keeping you informed on the best practices for maintaining the health and beauty of your trees in New Rochelle, NY.',
        "posts": [
            {
                "id": '1',
                "title": 'The Essential Guide to Tree Pruning in New Rochelle',
                "metaTitle": 'Tree Pruning Best Practices in New Rochelle | New Rochelle Tree Service Blog',
                "excerpt": 'Understand the importance and techniques of proper tree pruning in New Rochelle to maintain healthy and attractive trees.',
                "slug": 'guide-to-tree-pruning-new-rochelle',
                "tags": ['Tree Pruning', 'Tree Care Tips', 'New Rochelle'],
                "metaDescription": 'Learn the art and science of tree pruning with our comprehensive guide, tailored for the tree species and climate of New Rochelle, NY.'
            },
            {
                "id": '2',
                "title": 'How to Prepare Your Trees for New Rochelle’s Winter',
                "metaTitle": 'Winter Tree Care in New Rochelle | New Rochelle Tree Service Blog',
                "excerpt": 'Discover essential tips to prepare your trees for the winter season in New Rochelle, ensuring their health and resilience.',
                "slug": 'preparing-trees-for-winter-new-rochelle',
                "tags": ['Winter Tree Care', 'Tree Maintenance', 'New Rochelle'],
                "metaDescription": 'Our guide provides practical advice on protecting and caring for your trees during the colder months in New Rochelle.'
            },
            {
                "id": '3',
                "title": 'Understanding the Benefits of Regular Tree Maintenance',
                "metaTitle": 'Advantages of Regular Tree Maintenance | New Rochelle Tree Service Blog',
                "excerpt": 'Explore the long-term benefits of regular tree maintenance and how it contributes to the overall health and beauty of your landscape.',
                "slug": 'benefits-regular-tree-maintenance',
                "tags": ['Tree Maintenance', 'Landscape Health', 'Tree Care'],
                "metaDescription": 'Regular tree maintenance is key to a vibrant landscape. Learn how consistent care can enhance the life and appearance of your trees.'
            },
            {
                "id": '4',
                "title": 'Top Challenges in Urban Tree Care and How to Overcome Them',
                "metaTitle": 'Urban Tree Care Challenges in New Rochelle | New Rochelle Tree Service Blog',
                "excerpt": 'Urban tree care comes with unique challenges. Discover effective strategies to manage and overcome these obstacles in New Rochelle.',
                "slug": 'urban-tree-care-challenges',
                "tags": ['Urban Tree Care', 'Tree Management', 'New Rochelle'],
                "metaDescription": 'This article addresses common issues faced in urban tree care and offers solutions to ensure healthy trees in city environments like New Rochelle.'
            }
        ]
    },
    "treePruning": {
        "url": 'https://www.newrochelletreeservice.com/tree-pruning',
        "metaData": {
            "title": 'Expert Tree Pruning in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Professional tree pruning services in New Rochelle, NY to enhance the health and appearance of your trees. Contact us for expert pruning solutions.'
        },
        "h1": 'Tree Pruning Services in New Rochelle, NY',
        "subtitle": 'Our professional tree pruning services are designed to improve the health and beauty of your trees, utilizing the best practices tailored to New Rochelle’s unique environment.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Pruning Services</Heading>
                <Text>At New Rochelle Tree Service, we offer comprehensive tree pruning, including health pruning,
                    structural pruning, aesthetic pruning, and safety pruning to address various needs.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Us for Tree Pruning?</Heading>
                <Text>Our experienced arborists use the latest techniques and tools for effective tree pruning, ensuring
                    the best results for your trees and landscape in New Rochelle.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Contact Us for Tree Pruning</Heading>
                <Text>For professional tree pruning services in New Rochelle, NY, contact New Rochelle Tree Service.
                    Call us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> to schedule a service.</Text>
            </Stack>
        </Box>
    },
    "treeRemoval": {
        "url": 'https://www.newrochelletreeservice.com/tree-removal/',
        "metaData": {
            "title": 'Safe and Efficient Tree Removal in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Need a tree removed in New Rochelle, NY? Our experts provide safe and efficient tree removal services. Contact us for reliable tree removal solutions.'
        },
        "h1": 'Tree Removal Services in New Rochelle, NY',
        "subtitle": 'New Rochelle Tree Service offers expert tree removal services, ensuring the safety and aesthetic appeal of your property. Our team handles each project with care and professionalism.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Removal Services</Heading>
                <Text>We specialize in dangerous tree removal, dead tree removal, diseased tree removal, and obstructive
                    tree removal, using safe and effective methods.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose New Rochelle Tree Service?</Heading>
                <Text>Our experienced team prioritizes safety and efficiency in every tree removal project, ensuring
                    minimal impact on your property and surroundings.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule Your Tree Removal</Heading>
                <Text>Contact New Rochelle Tree Service for professional tree removal services in New Rochelle, NY. Call
                    us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for assistance.</Text>
            </Stack>
        </Box>
    },
    "treeTrimming": {
        "url": 'https://www.newrochelletreeservice.com/tree-trimming/',
        "metaData": {
            "title": 'Professional Tree Trimming in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Expert tree trimming services in New Rochelle, NY to maintain the health and beauty of your trees. Contact us for tailored trimming solutions.'
        },
        "h1": 'Tree Trimming Services in New Rochelle, NY',
        "subtitle": 'At New Rochelle Tree Service, we offer specialized tree trimming services to enhance the health, safety, and aesthetic appeal of your trees. Our skilled team understands the specific needs of trees in the New Rochelle area.',
        "body": <Box>
            <Heading as="h2" size="lg" mb={4}>The Importance of Tree Trimming</Heading>
            <Text fontSize="md">
                Regular tree trimming is essential for maintaining tree health, ensuring safety, and enhancing the
                aesthetic appeal of your property. At New Rochelle Tree Service, our certified arborists use
                top-notch techniques to prune trees effectively, promoting robust growth and preventing disease and
                pest infestation. Discover how our tree care services can transform your landscape and protect your
                investment.
            </Text>
            <Text>Tree trimming is an essential aspect of arboriculture that goes beyond the simple act of cutting
                branches. It is a sophisticated procedure to enhance trees' health, safety, and aesthetic appeal. When
                conducted by certified arborists, this practice can significantly contribute to your trees' longevity
                and vibrancy. Understanding the intricacies of tree trimming can help property owners appreciate the
                value this service brings to their landscapes and overall property well-being.</Text>

            <Text>Firstly, tree trimming is pivotal in maintaining a tree's health. Overgrown branches can strain a
                tree, diverting energy from its growth and health maintenance. Removing these excess limbs allows the
                tree to focus on developing a robust structure and thriving foliage. Additionally, removing dead or
                diseased branches prevents the spread of decay and pests that could further harm the tree or even spread
                to adjacent plants. This careful pruning promotes better air circulation and sunlight penetration, two
                critical factors for photosynthesis, thus enhancing the tree's overall health.</Text>
            <Heading as="h2" size="lg" mb={4}>Our Expert Tree Trimming Process</Heading>
            <UnorderedList spacing={3}>
                <ListItem>Assessment: Our arborists begin with a thorough assessment to understand the specific needs of
                    your trees.</ListItem>
                <ListItem>Planning: We develop a customized trimming plan that aligns with the health goals and
                    aesthetic preferences for your landscape.</ListItem>
                <ListItem>Execution: Using state-of-the-art equipment, we carefully trim and prune your trees, ensuring
                    precise cuts and minimal stress on the trees.</ListItem>
                <ListItem>Cleanup: After trimming, we meticulously clean up the area, leaving your property tidy and
                    enhanced.</ListItem>
            </UnorderedList>
            <Heading as="h2" size="lg" mb={4}>Benefits of Choosing Professional Tree Trimming Services</Heading>
            <Text><b>Promotes Tree Health:</b>&nbsp;Trimming removes dead or diseased branches, aiding in the prevention
                of
                further decay and
                encouraging healthier growth.</Text>
            <Text><b>Enhances Safety:</b>&nbsp;Removal of hazardous branches reduces the risk of property damage and
                personal
                injury during
                storms or high winds.</Text>
            <Text><b>Improves Aesthetics:</b>&nbsp;Professional trimming shapes trees beautifully, enhancing your
                property’s
                overall curb
                appeal.</Text>
            <Text><b>Increases Fruit Production:</b>&nbsp;For fruit-bearing trees, proper trimming can stimulate the
                production of more fruit by
                removing excess branches and allowing more sunlight and air to reach the fruiting parts of the
                tree.</Text>
            <Text><b>Improves View and Lets in More Light:</b>&nbsp;Strategically removing branches can enhance your
                property's view and increase natural light,
                making your outdoor spaces more enjoyable.</Text>
            <Text><b>Prevents Disease Spread:</b>&nbsp;By removing infected branches and thinning out dense areas of the
                tree, trimming can prevent
                the spread of tree diseases, keeping your trees healthy and strong.</Text>
            <Text>Safety is another critical aspect addressed by tree trimming. Overgrown or dead branches pose
                significant risks during severe weather conditions. They can break off, causing property damage or
                injuring people. Regular trimming ensures these potential hazards are mitigated, providing a safer
                environment for the property and its inhabitants. Moreover, strategic trimming can prevent trees from
                interfering with power lines, a common cause of power outages and, in some cases, fires.</Text>

            <Text>From an aesthetic perspective, tree trimming can dramatically transform the appearance of a property.
                Well-maintained trees are visually appealing and can increase property value. Professional arborists
                have the skill to shape trees in ways that complement the landscape's overall design, highlighting the
                property's architectural features and creating a more inviting outdoor space. This visual enhancement is
                not only beneficial for homeowners who enjoy their outdoor living spaces but also for commercial
                properties looking to attract customers or tenants.</Text>

            <Text>Furthermore, regular trimming is crucial for optimizing fruit production for fruit-bearing trees. It
                encourages the growth of spurs, the part of the tree that produces fruit. By removing excess branches,
                more energy is directed towards fruit production, resulting in a more bountiful harvest. Additionally,
                the improved air circulation and sunlight exposure from a well-pruned tree reduce the likelihood of
                disease and pest infestation, further ensuring a healthy crop.</Text>
            <Heading as="h2" size="lg" mb={4}>Why New Rochelle Residents Choose Us for Tree Trimming</Heading>
            <Text fontSize="md">
                Our commitment to excellence and eco-friendly tree care sets us apart. With New Rochelle Tree Service,
                you're choosing a tree trimming service that values the health of your trees and your satisfaction. Our
                certified arborists are experts in their field, ensuring that your tree care needs are met with the
                highest standards of quality and professionalism.
            </Text>

            <Text>Tree trimming is a multifaceted service that addresses concerns about tree health,
                safety, aesthetics, and productivity. Tree trimming demands a deep understanding of tree biology, growth
                patterns, and environmental factors. Property owners are encouraged to seek professional tree trimming
                services to care for their trees properly. Choosing a reputable tree service company, like New Rochelle
                Tree Service, ensures trees survive and thrive, improving the beauty and safety of your property for
                years to come.</Text>

            <Heading as="h2" size="lg" mb={4}>Ready to Transform Your Landscape?</Heading>
            <Text fontSize="md" mb={4}>Schedule your tree trimming service with New Rochelle Tree Service today and see
                the difference professional care can make for your trees.</Text>
            <Text>For top-quality tree trimming services in New Rochelle, NY, contact New Rochelle Tree Service.
                Call us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> to arrange a service.</Text>

        </Box>
    },
    "stumpGrinding": {
        "url": 'https://www.newrochelletreeservice.com/stump-grinding',
        "metaData": {
            "title": 'Efficient Stump Grinding in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Remove unsightly tree stumps with our efficient stump grinding services in New Rochelle, NY. Enhance your landscape’s appearance and safety.'
        },
        "h1": 'Stump Grinding Services in New Rochelle, NY',
        "subtitle": 'New Rochelle Tree Service offers comprehensive stump grinding services to effectively remove tree stumps, enhancing the safety and aesthetics of your property.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Stump Grinding Services</Heading>
                <Text>Our services include advanced stump grinding techniques, debris removal, and post-grinding
                    landscape restoration, tailored for properties in New Rochelle.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Us?</Heading>
                <Text>Our experienced team utilizes state-of-the-art equipment for efficient and safe stump removal,
                    ensuring a smooth and satisfactory experience.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule Stump Grinding</Heading>
                <Text>Contact New Rochelle Tree Service for professional stump grinding services. Call us at <Link
                    href={phoneNumber}>{phoneNumberWithDashes}</Link> to get started.</Text>
            </Stack>
        </Box>
    },
    "treeHealthConsulting": {
        "url": 'https://www.newrochelletreeservice.com/tree-health-consulting',
        "metaData": {
            "title": 'Expert Tree Health Consulting in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Ensure the health of your trees with our expert tree health consulting services in New Rochelle, NY. Get advice from certified arborists.'
        },
        "h1": 'Tree Health Consulting in New Rochelle, NY',
        "subtitle": 'At New Rochelle Tree Service, our certified arborists provide in-depth tree health consulting to diagnose, treat, and maintain the health of your trees, tailored to New Rochelle’s environment.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Health Consulting Services</Heading>
                <Text>We offer comprehensive health assessments, nutrition planning, disease and pest management, and
                    tree care education, focusing on New Rochelle’s specific environmental conditions.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Our Consulting?</Heading>
                <Text>Our team brings extensive knowledge and a personalized approach to each consultation, ensuring the
                    best care for your trees in New Rochelle.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Contact Us for Tree Health Consulting</Heading>
                <Text>For professional tree health consulting in New Rochelle, NY, contact New Rochelle Tree Service.
                    Reach us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for a consultation.</Text>
            </Stack>
        </Box>
    },
    "landClearing": {
        "url": 'https://www.newrochelletreeservice.com/land-clearing',
        "metaData": {
            "title": 'Efficient Land Clearing Services in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Get your land ready for any project with our efficient and eco-friendly land clearing services in New Rochelle, NY.'
        },
        "h1": 'Land Clearing Services in New Rochelle, NY',
        "subtitle": 'New Rochelle Tree Service provides comprehensive land clearing services, ensuring your property is prepared for new developments or renovations with minimal environmental impact.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Land Clearing Services</Heading>
                <Text>Our services include tree and brush removal, stump grinding, and site preparation, utilizing
                    eco-friendly methods to minimize impact on the New Rochelle environment.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Us for Land Clearing?</Heading>
                <Text>Our team uses advanced equipment and techniques to ensure efficient land clearing while adhering
                    to environmental safety standards.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Contact Us for Land Clearing</Heading>
                <Text>Ready to clear your land in New Rochelle, NY? Contact New Rochelle Tree Service for professional
                    land clearing services. Call us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> to get
                    started.</Text>
            </Stack>
        </Box>
    },
    "arboristConsulting": {
        "url": 'https://www.newrochelletreeservice.com/arborist-consultations',
        "metaData": {
            "title": 'Professional Arborist Consultations in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Get expert advice on tree care from certified arborists in New Rochelle, NY. Our consultations cover tree health, risk assessment, and maintenance planning.'
        },
        "h1": 'Arborist Consultation Services in New Rochelle, NY',
        "subtitle": 'Our certified arborists at New Rochelle Tree Service provide expert consultations, offering guidance on tree health, risk assessment, and care strategies tailored to the New Rochelle area.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Arborist Consulting Services</Heading>
                <Text>Receive comprehensive advice on tree health, risk management, and maintenance. We cater to
                    residential and commercial clients, ensuring the best care for trees in New Rochelle.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Consult with Our Arborists?</Heading>
                <Text>Our arborists bring in-depth knowledge of local tree species and environmental conditions,
                    providing customized solutions for your specific tree care needs.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Schedule an Arborist Consultation</Heading>
                <Text>For expert arborist advice in New Rochelle, NY, contact New Rochelle Tree Service. Call us
                    at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for a professional consultation.</Text>
            </Stack>
        </Box>
    },
    "treePlanting": {
        "url": 'https://www.newrochelletreeservice.com/tree-planting',
        "metaData": {
            "title": 'Expert Tree Planting Services in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Enhance your landscape with our professional tree planting services in New Rochelle, NY. Choose the right trees for your property with our expert guidance.'
        },
        "h1": 'Tree Planting Services in New Rochelle, NY',
        "subtitle": 'New Rochelle Tree Service offers expert tree planting and transplanting services, ensuring the healthy growth of your trees and the enhancement of your landscape.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Planting Services</Heading>
                <Text>We provide professional tree planting, including species selection, proper planting techniques,
                    and post-planting care, tailored to the New Rochelle environment.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Plant with Us?</Heading>
                <Text>Our team’s expertise in local tree species and conditions ensures your new trees are perfectly
                    suited for your landscape and thrive in New Rochelle’s climate.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Get Started with Tree Planting</Heading>
                <Text>Contact New Rochelle Tree Service for professional tree planting services. Call us at <Link
                    href={phoneNumber}>{phoneNumberWithDashes}</Link> to enhance your property’s greenery.</Text>
            </Stack>
        </Box>
    },
    "emergencyTreeServices": {
        "url": 'https://www.newrochelletreeservice.com/emergency-tree-services',
        "metaData": {
            "title": '24/7 Emergency Tree Services in New Rochelle, NY | New Rochelle Tree Service',
            "description": 'Immediate response for emergency tree services in New Rochelle, NY. Contact us for urgent tree care, including storm damage and hazard removal.'
        },
        "h1": 'Emergency Tree Services in New Rochelle, NY',
        "subtitle": 'New Rochelle Tree Service offers fast, reliable emergency tree services, responding promptly to urgent tree care needs caused by storms, damage, or other unforeseen events.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Emergency Tree Services</Heading>
                <Text>We provide rapid response for storm-damaged trees, fallen limbs, and other emergency scenarios,
                    prioritizing safety and quick resolution.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Rely on Our Emergency Services?</Heading>
                <Text>Our team is equipped and ready to handle emergency situations, ensuring the safety of your
                    property and reducing further risk.</Text>
            </Stack>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Need Emergency Tree Service?</Heading>
                <Text>In need of emergency tree service in New Rochelle, NY? Contact New Rochelle Tree Service
                    immediately. Call us at <Link href={phoneNumber}>{phoneNumberWithDashes}</Link> for urgent
                    assistance.</Text>
            </Stack>
        </Box>
    },
    "404": {
        "metaData": {
            "title": 'Page Not Found - New Rochelle Tree Service | Tree Care',
            "description": 'Oops! The page you’re seeking could not be found. New Rochelle Tree Service offers professional tree care services. Reach out to us for reliable help.',
            "siteName": 'Tree Service in New Rochelle, NY',
        },
        "h1": '404 - Oops, This Page is Missing.',
        "subtitle": 'Unfortunately, the page you are looking for does not exist or has been moved.',
        "p": 'Looking for tree trimming, stump removal, or other tree care services? We’ve got you covered. Contact New Rochelle Tree Service for all your tree care needs in New Rochelle, NY.',
    }
}